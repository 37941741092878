var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.visible)?_c('div',{staticClass:"ucrs-autocompleteDropdown"},[_c('div',{staticClass:"ucrs-autocompleteDropdown__inner"},[(_vm.$slots.header)?_c('div',{staticClass:"ucrs-autocompleteDropdown__header"},[_vm._t("header")],2):_c('ul',{ref:"ZonesList",staticClass:"ucrs-autocompleteDropdown__menu",on:{"mouseover":function($event){return _vm.$emit('mouseover')},"mouseleave":function($event){return _vm.$emit('mouseleave')}}},_vm._l((_vm.items),function(item,i){return _c('li',{key:i,ref:"ZoneElements",refInFor:true,class:[
          'ucrs-autocompleteDropdown__item text-black-light',
          {
            'ucrs-autocompleteDropdown__item--selected':
              item.selected && i !== _vm.hoveredZoneIndex,
            'bg-blue text-white': i === _vm.hoveredZoneIndex,
          },
        ],on:{"click":function($event){return _vm.onItemClick(item)}}},[_c('span',[_vm._v(_vm._s(item[_vm.field]))]),_vm._v(" "),_c('span',{class:[
            'ucrs-autocompleteDropdown__item-check border-black-light',
            {
              'after:opacity-0': !item.selected,
              'after:opacity-100': item.selected,
              'border-white': i === _vm.hoveredZoneIndex,
              'after:border-blue': item.selected && i !== _vm.hoveredZoneIndex,
              'after:border-white': item.selected && i === _vm.hoveredZoneIndex,
            },
          ]})])}),0),_vm._v(" "),(_vm.$slots.footer)?_c('div',{staticClass:"ucrs-autocompleteDropdown__footer"},[_vm._t("footer"),_vm._v(" "),_c('OButton',{attrs:{"variant":"secondary"},on:{"click":function($event){return _vm.$emit('dropdown-cancel')}}},[_vm._v("Annulla")]),_vm._v(" "),_c('OButton',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$emit('dropdown-submit')}}},[_vm._v("Conferma")])],2):_vm._e()])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }