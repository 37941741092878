var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ValidationProvider',{attrs:{"vid":_vm.vid,"name":_vm.$attrs.name,"slim":true,"rules":_vm.rules}},[_c('OField',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onOutsideClick),expression:"onOutsideClick"}],attrs:{"variant":_vm.fieldVariant,"message":_vm.fieldMessage,"label":_vm.label,"root-class":_vm.fieldClasses,"data-name":_vm.$attrs.name}},[_c('OAutocomplete',{ref:"Autocomplete",attrs:{"value":_vm.selectedGeoLabel,"placeholder":_vm.$attrs.placeholder,"group-field":_vm.dataGroups ? 'type' : null,"group-options":_vm.dataGroups ? 'items' : null,"field":_vm.itemLabelField,"data":_vm.dropdownVisible ? [] : _vm.geoItems,"name":_vm.$attrs.name,"required":`${_vm.rules.includes('required')}`,"open-on-focus":"","root-class":[
        ...(_vm.rootClass ? [_vm.rootClass] : []),
        ...(_vm.errors?.[_vm.$attrs.name]?.[0] ? ['ucrs-autocomplete--danger'] : []),
        ...(_vm.dropdownVisible
          ? ['ucrs-autocomplete--focus ucrs-autocomplete--noBorderBottom']
          : []),
      ],"input-class":_vm.inputClasses,"debounce-typing":500},on:{"typing":_vm.onTyping,"select":_vm.onSelect,"focus":_vm.onFocus,"blur":_vm.onBlur},nativeOn:{"keydown":function($event){return _vm.onKeydown.apply(null, arguments)}},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('span',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html",value:(
            _vm.$options.highlightSearchedText(props.option[_vm.itemLabelField], _vm.text)
          ),expression:"\n            $options.highlightSearchedText(props.option[itemLabelField], text)\n          "}],staticClass:"ucrs-autocomplete__result"})]}}])},[_vm._v(" "),_c('div',{attrs:{"slot":"empty"},slot:"empty"},[_vm._v("Nessuna località trovata")])]),_vm._v(" "),_c('AutocompleteDropdown',{attrs:{"visible":_vm.dropdownVisible,"items":_vm.geoZonesItems,"hovered-zone-index":_vm.hoveredZoneIndex,"fetching-zones":_vm.fetchingZones},on:{"item-select":_vm.onZoneSelect,"mouseover":function($event){_vm.hoveredZoneIndex = -1},"mouseleave":function($event){_vm.hoveredZoneIndex = 0}}}),_vm._v(" "),(_vm.fetchingZones)?_c('div',{staticClass:"bg-white space-y-2 p-2 absolute top-full left-0 right-0 z-20"},[_c('OSkeleton',{attrs:{"width":"100%","height":"32px","rounded":false}}),_vm._v(" "),_c('OSkeleton',{attrs:{"width":"100%","height":"32px","rounded":false}}),_vm._v(" "),_c('OSkeleton',{attrs:{"width":"100%","height":"32px","rounded":false}}),_vm._v(" "),_c('OSkeleton',{attrs:{"width":"100%","height":"32px","rounded":false}})],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }