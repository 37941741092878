<template>
  <ValidationProvider
    :vid="vid"
    :name="$attrs.name"
    :slim="true"
    :rules="rules"
  >
    <OField
      v-click-outside="onOutsideClick"
      :variant="fieldVariant"
      :message="fieldMessage"
      :label="label"
      :root-class="fieldClasses"
      :data-name="$attrs.name"
    >
      <OAutocomplete
        ref="Autocomplete"
        :value="selectedGeoLabel"
        :placeholder="$attrs.placeholder"
        :group-field="dataGroups ? 'type' : null"
        :group-options="dataGroups ? 'items' : null"
        :field="itemLabelField"
        :data="dropdownVisible ? [] : geoItems"
        :name="$attrs.name"
        :required="`${rules.includes('required')}`"
        open-on-focus
        :root-class="[
          ...(rootClass ? [rootClass] : []),
          ...(errors?.[$attrs.name]?.[0] ? ['ucrs-autocomplete--danger'] : []),
          ...(dropdownVisible
            ? ['ucrs-autocomplete--focus ucrs-autocomplete--noBorderBottom']
            : []),
        ]"
        :input-class="inputClasses"
        :debounce-typing="500"
        @typing="onTyping"
        @select="onSelect"
        @focus="onFocus"
        @blur="onBlur"
        @keydown.native="onKeydown"
      >
        <template slot-scope="props">
          <span
            v-dompurify-html="
              $options.highlightSearchedText(props.option[itemLabelField], text)
            "
            class="ucrs-autocomplete__result"
          />
        </template>
        <div slot="empty">Nessuna località trovata</div>
      </OAutocomplete>
      <AutocompleteDropdown
        :visible="dropdownVisible"
        :items="geoZonesItems"
        :hovered-zone-index="hoveredZoneIndex"
        :fetching-zones="fetchingZones"
        @item-select="onZoneSelect"
        @mouseover="hoveredZoneIndex = -1"
        @mouseleave="hoveredZoneIndex = 0"
      />
      <div
        v-if="fetchingZones"
        class="bg-white space-y-2 p-2 absolute top-full left-0 right-0 z-20"
      >
        <OSkeleton width="100%" height="32px" :rounded="false" />
        <OSkeleton width="100%" height="32px" :rounded="false" />
        <OSkeleton width="100%" height="32px" :rounded="false" />
        <OSkeleton width="100%" height="32px" :rounded="false" />
      </div>
    </OField>
  </ValidationProvider>
</template>

<script>
// Libs
import { mapMutations } from 'vuex'
import { highlightSearchedText } from '~/assets/js/methods'

// Mixins
import InputDropdownErrorsMixin from '~/mixins/input-dropdown-errors'

// Components
import AutocompleteDropdown from '~/components/AutocompleteDropdown.vue'

const OptionsTypes = {
  Comune: 'c',
}

const DataTypes = {
  p: 'Province',
  c: 'Comuni',
  q: 'Zone',
}

export default {
  components: { AutocompleteDropdown },

  mixins: [InputDropdownErrorsMixin],

  inheritAttrs: false,

  highlightSearchedText,

  props: {
    vid: {
      type: String,
      default: '',
    },

    geo: {
      type: Object,
      default: null,
    },

    geoZones: {
      type: Array,
      default: () => {
        return []
      },
    },

    rules: {
      type: [Object, String],
      default: '',
    },

    errors: {
      type: Object,
      default: () => {
        return null
      },
    },

    label: {
      type: String,
      default: '',
    },

    itemLabelField: {
      type: String,
      default: 'name',
    },

    fieldClasses: {
      type: String,
      default: '',
    },

    rootClass: {
      type: String,
      default: '',
    },

    inputClasses: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      geoItems: [],
      geoZonesItems: [],
      dataGroups: true,
      text: '',
      dropdownVisible: false,
      hoveredItem: null,
      hoveredZoneIndex: 0,
      fetchingZones: false,
    }
  },

  computed: {
    fieldVariant() {
      const { errors, text, $attrs } = this

      const fieldName = $attrs.name

      if (text || !errors[fieldName]?.length) {
        return
      }

      return 'danger'
    },

    fieldMessage() {
      const { errors, text, $attrs } = this

      const fieldName = $attrs.name

      if (text || !errors[fieldName]?.length) {
        return
      }

      return errors[fieldName][0]
    },

    selectedGeoLabel() {
      const { geo } = this

      if (!geo) {
        return
      }

      const { geoZones, itemLabelField } = this

      let selectedGeoLabel

      switch (true) {
        case geoZones.length === 1:
          selectedGeoLabel = `${geo.comuneNome} (Comune) | 1 zona`
          break
        case geoZones.length > 1:
          selectedGeoLabel = `${geo.comuneNome} (Comune) | ${geoZones.length} zone`
          break
        default:
          selectedGeoLabel = geo[itemLabelField]
      }

      return selectedGeoLabel
    },

    geoZonesMap() {
      const { geoZones } = this

      if (!geoZones.length) {
        return
      }

      const geoZonesMap = {}

      for (const it of geoZones) {
        geoZonesMap[it.id] = null
      }

      return geoZonesMap
    },
  },

  watch: {
    hoveredItem(hovered) {
      if (!hovered) return

      this.getInputEl().setAttribute(
        'aria-activedescendant',
        hovered[this.dropdownVisible ? 'name' : this.itemLabelField]
      )
    },
    geo: {
      immediate: true,
      async handler(geo) {
        const { geoZonesMap } = this

        if (!geo || !geoZonesMap) {
          return
        }

        const cityId = geo.comune

        const geoRes = await this.$axios.get(
          `_api/api/v2/geo/cities/${cityId}`,
          {
            params: { embed: 'zones' },
          }
        )

        const { zones } = geoRes.data.data

        this.geoZonesItems = zones.map((it) => ({
          ...it,
          selected: it.id in geoZonesMap,
        }))
      },
    },

    dropdownVisible(dropdownVisible) {
      if (!dropdownVisible && !this.geoZones.length) {
        this.$emit('blur')
        return
      }

      if (!dropdownVisible) {
        this.$emit('blur-dropdown')
      }
    },
    geoItems: {
      handler(geoItems) {
        this.getInputEl().removeAttribute('aria-activedescendant')
        let items = []
        for (const obj of geoItems) {
          if ('items' in obj) {
            items = [...items, ...obj.items]
          } else {
            items = [...items, obj]
          }
        }
        this.$nextTick(() => {
          const { Autocomplete } = this.$refs
          const autocompleteItems = [
            ...Autocomplete.$el.querySelectorAll('.ucrs-autocomplete__item'),
          ].filter((el) => {
            return ![...el.classList].find(
              (cl) => cl === 'ucrs-autocomplete__item--title'
            )
          })
          for (let i = 0; i < items.length; i++) {
            autocompleteItems[i].id = items[i].testo
          }
        })
      },
      deep: true,
    },
  },

  mounted() {
    const { geo } = this

    if (!geo) {
      return
    }

    this.onTyping(geo.comuneNome || geo.provinciaNome)
  },

  beforeDestroy() {
    this.text = ''
    this.geoItems = []
    this.geoZonesItems = []
  },

  methods: {
    ...mapMutations('search', ['setGeoZones']),
    getInputEl() {
      return this.$refs.Autocomplete.$el.querySelector('input')
    },

    onKeydown(event) {
      const downWhileZonesSelected =
        event.key === 'ArrowDown' &&
        this.geoZonesItems.length > 0 &&
        this.geo.testo === this.getInputEl().value

      if (downWhileZonesSelected) this.dropdownVisible = true

      if (!this.dropdownVisible) {
        this.hoveredItem = this.$refs.Autocomplete.hovered
        return
      }

      switch (event.key) {
        case 'ArrowDown':
          if (this.hoveredZoneIndex >= this.geoZonesItems.length - 1) return
          this.hoveredZoneIndex++
          return
        case 'ArrowUp':
          if (this.hoveredZoneIndex <= 0) return
          this.hoveredZoneIndex--
          return
        case 'Enter':
          if (this.geoZonesItems.length === 0) return
          this.geoZonesItems[this.hoveredZoneIndex].selected = !this
            .geoZonesItems[this.hoveredZoneIndex].selected
          this.onZoneSelect(this.geoZonesItems[this.hoveredZoneIndex])
          return
        case 'Tab':
        case 'Escape':
          this.dropdownVisible = false
      }

      this.hoveredItem = this.geoZonesItems[this.hoveredZoneIndex]
    },
    async onTyping(text) {
      this.dropdownVisible = false

      if (!text) {
        this.text = ''
        this.geoItems = []
        return
      }

      this.text = text

      const search = text.trim()

      if (search.length < 2) {
        this.geoItems = []
        return
      }

      const geoRes = await this.$axios.get('_api/api/v2/geo/search', {
        params: { search },
      })
      const geoData = geoRes.data

      this.geoItems = []

      if (!geoData.length) {
        return
      }

      const geoDataPreciseItems = geoData.filter((i) => i.preciso === 1)

      if (geoDataPreciseItems.length < 2) {
        this.dataGroups = true
        const types = [...new Set(geoData.map((el) => el.tipo))]
        types.forEach((type) => {
          const items = geoData.filter((el) => el.tipo === type)
          this.geoItems.push({ type: DataTypes[type], items })
        })

        return
      }

      this.dataGroups = false

      const [geoDataProvinceItem, geoDataCityItem] = geoDataPreciseItems

      geoDataProvinceItem.testo =
        geoDataProvinceItem.provinciaNome + ' (Provincia)'
      geoDataCityItem.testo = geoDataCityItem.comuneNome + ' (Tutto il comune)'

      if (!geoDataCityItem.conQuartieri) {
        this.geoItems = geoDataPreciseItems
        return
      }

      this.geoItems = [
        geoDataProvinceItem,
        { ...geoDataCityItem, noZones: true },
        {
          ...geoDataCityItem,
          testo: geoDataCityItem.comuneNome + ' (Scegli le zone)',
          preciso: 1,
        },
      ]
    },

    onSelect(option) {
      if (!option) {
        return
      }

      if (this.geo?.comune !== option.comune) {
        this.geoZonesItems = []
        this.$emit('clear-zones')
      }

      this.$emit('select', option)

      switch (true) {
        case !option:
        case !option.conQuartieri:
        case option.noZones:
        case option.tipo !== OptionsTypes.Comune:
          return
      }

      const cityId = option.comune
      this.fetchingZones = true
      this.$axios
        .get(`_api/api/v2/geo/cities/${cityId}`, {
          params: { embed: 'zones' },
        })
        .then((geoRes) => {
          const { zones } = geoRes.data.data
          this.geoZonesItems = zones.map((it) => ({ ...it, selected: false }))
        })
        .finally(() => {
          this.setGeoZones([])
          this.fetchingZones = false
          this.dropdownVisible = true
        })
    },

    onZoneSelect(item) {
      // This hack is required because the OAuto/OAutocomplete component emits a 'select'
      // event with a null payload on the first set of selectedGeoLabel below, so
      // we must replace the previous state of selectedGeo for future zones selections.
      const prevGeo = this.geo
      setTimeout(() => {
        this.$emit('select', prevGeo)
      }, 1)

      this.$emit('select-zone', { ...item })
    },

    onFocus() {
      this.$emit('focus')

      if (
        (!this.dropdownVisible && !this.geoZonesItems.length) ||
        !this.geoZonesItems.length
      ) {
        this.dropdownVisible = false
        return
      }

      const inputValue = this.getInputEl().value

      const inputHasSelectedZone = /(zona|zone)$/.test(inputValue)
      if (
        (this.geo?.testo === inputValue || inputHasSelectedZone) &&
        !this.fetchingZones
      ) {
        this.dropdownVisible = true
      }
    },

    onBlur() {
      setTimeout(() => {
        this.$emit('blur')
      }, 300)
    },

    onOutsideClick() {
      this.dropdownVisible = false
    },
  },
}
</script>

<style lang="postcss">
@import '~/assets/css/components/autocomplete';
@import '~/assets/css/components/field';
</style>
